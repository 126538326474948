import {
  INVALID_PHONE_FORMAT,
  MAX_CONTACT_US_MESSAGE,
  MAX_CONTACT_US_MESSAGE_LENGTH,
  MAX_INPUT_LENGTH,
  MIN_PHONE_NUMBER_LENGTH,
} from "@constants/commons";
import { FAILED_PASSWORD } from "@constants/errors";
import {
  REQUIRED_FIELD,
  CODE_TEMPLATE_LENGTH,
  RETRY_PASSWORD_MATCH,
  EMAIL_TEMPLATE_MATCH,
  PHONE_NUMBER_TEMPLATE_MATCH,
  REQUIRED_FIELD_LANDING,
} from "@constants/validation";
import * as yup from "yup";
import {
  validateEmail,
  validatePassword,
  validatePhoneNumberWithouCode,
} from "./regexps";
import { getPhoneNumberValue } from "@services/common";

const emailValidation = yup
  .string()
  .max(MAX_INPUT_LENGTH, EMAIL_TEMPLATE_MATCH)
  .test(
    "email-regex",
    EMAIL_TEMPLATE_MATCH,
    (value) => !value?.length || validateEmail.test(value)
  );

const phoneNumberValidation = yup
  .string()
  .max(MAX_INPUT_LENGTH, PHONE_NUMBER_TEMPLATE_MATCH)
  .test("phone-regex", PHONE_NUMBER_TEMPLATE_MATCH, (value) => {
    const phoneNumberValue = getPhoneNumberValue(value);

    return (
      !phoneNumberValue?.length ||
      validatePhoneNumberWithouCode.test(phoneNumberValue)
    );
  });

export const validationSchemas = {
  signIn: yup.object().shape({
    email: yup.string().max(MAX_INPUT_LENGTH, EMAIL_TEMPLATE_MATCH),
    phone_number: yup
      .string()
      .max(MAX_INPUT_LENGTH, PHONE_NUMBER_TEMPLATE_MATCH),
  }),
  signUp: yup.object().shape({
    email: yup.string().max(MAX_INPUT_LENGTH, EMAIL_TEMPLATE_MATCH),
    phone_number: yup
      .string()
      .max(MAX_INPUT_LENGTH, PHONE_NUMBER_TEMPLATE_MATCH),
    confirmTerms: yup.boolean().required(REQUIRED_FIELD),
  }),
  codeConf: yup.object().shape({
    code: yup.string().required(REQUIRED_FIELD).length(6, CODE_TEMPLATE_LENGTH),
  }),
  codeResendConf: yup.object().shape({
    code: yup.string(),
  }),
  passwordConf: yup.object().shape({
    password: yup.string().required(REQUIRED_FIELD),
  }),
  forgotPass: yup.object().shape({
    password: yup.string(),
  }),
  passwordCreate: yup.object().shape({
    email: yup.string(),
    phone_number: yup.string(),
    password: yup.string().matches(validatePassword, FAILED_PASSWORD),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password")], RETRY_PASSWORD_MATCH),
  }),
  manageEvent: yup.object().shape({
    eventName: yup.string().required(REQUIRED_FIELD),
    assignedSalesRep: yup.string(),
    eventTypes: yup.string(),
    remote: yup.string(),
    person: yup.string(),
    department: yup.string().when("person", {
      is: "true",
      then: yup.string().required(REQUIRED_FIELD),
    }),
    startdate: yup.date().required(REQUIRED_FIELD),
    enddate: yup.date().required(REQUIRED_FIELD),
    starttime: yup.date().required(REQUIRED_FIELD),
    endtime: yup.date().required(REQUIRED_FIELD),
    orderNumber: yup.string(),
  }),
  checkUser: yup.object().shape({
    email: yup.string().max(MAX_INPUT_LENGTH, EMAIL_TEMPLATE_MATCH),
    phone_number: yup
      .string()
      .min(MIN_PHONE_NUMBER_LENGTH, PHONE_NUMBER_TEMPLATE_MATCH)
      .max(MAX_INPUT_LENGTH, PHONE_NUMBER_TEMPLATE_MATCH),
  }),
  checkEndUser: yup.object().shape({
    email: emailValidation,
    phone_number: phoneNumberValidation,
  }),
  personalEndUserInfo: yup.object().shape({
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
    badge: yup.string(),
  }),
  personalSalesRepInfo: yup.object().shape({
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
    assignedDealers: yup.string(),
  }),
  personalDealerInfo: yup.object().shape({
    dealerName: yup.string().required(REQUIRED_FIELD),
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
    assignedParents: yup.string(),
  }),
  personalParentInfo: yup.object().shape({
    firstName: yup.string().required(REQUIRED_FIELD),
    lastName: yup.string().required(REQUIRED_FIELD),
  }),
  filters: yup.object().shape({
    upcomingDate: yup.string(),
    rangeDate: yup.string(),
    startDate: yup.date().when("rangeDate", {
      is: "true",
      then: yup.date().required(REQUIRED_FIELD),
    }),
    endDate: yup.date().when("rangeDate", {
      is: "true",
      then: yup.date().required(REQUIRED_FIELD),
    }),
    allLocations: yup.string(),
    locationOnly: yup.string(),
    locationRemote: yup.string(),
    filterStatus: yup.string(),
    filterProductType: yup.string(),
    filterCreator: yup.string(),
  }),
  manageMeasurements: yup.object().shape({}),
  viewMeasurements: yup.object().shape({
    measurementName: yup.string().required(REQUIRED_FIELD),
  }),
  manageProductTypes: yup.object().shape({
    productTitle: yup.string().required(REQUIRED_FIELD),
  }),
  landingContactUs: yup.object().shape({
    name: yup.string().trim().required(REQUIRED_FIELD_LANDING),
    companyName: yup.string().trim().required(REQUIRED_FIELD_LANDING),
    phoneNumber: yup
      .string()
      .required(REQUIRED_FIELD_LANDING)
      .matches(/^(\+1\d{10}|\+380\d{9})$/, INVALID_PHONE_FORMAT),
    message: yup
      .string()
      .trim()
      .max(
        MAX_CONTACT_US_MESSAGE_LENGTH,
        MAX_CONTACT_US_MESSAGE(MAX_CONTACT_US_MESSAGE_LENGTH)
      )
      .required(REQUIRED_FIELD_LANDING),
  }),
};
