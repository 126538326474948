import {
  AboutUsHeader,
  AboutUsBanner,
  AboutUsBenefits,
  AboutUsFeatures,
  AboutUsContactUs,
} from "@pages/Info/AboutUs/components";
import logoSrc from "@assets/img/landing_logo.svg";

export const AboutUs = () => {
  return (
    <section className="landing">
      <AboutUsHeader />
      <AboutUsBanner />
      <AboutUsBenefits />
      <AboutUsFeatures />
      <AboutUsContactUs />
      <footer className="landing-footer d-flex a-center jc-center">
        <a href="#landing-header">
          <img src={logoSrc} alt="FITEVENT" />
        </a>
      </footer>
    </section>
  );
};
