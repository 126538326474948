import React, {
  useMemo,
  useRef,
  useEffect,
  KeyboardEvent,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { validationSchemas } from "@services/validation";
import {
  ILandingContactUsForm,
  IInputFields,
} from "@models/components/main/landing";
import {
  MAX_CONTACT_US_MESSAGE_LENGTH,
  FORM_HAS_ERRORS_MESSAGE,
  FORM_SENT_SUCCESS_MESSAGE,
  SOMETHING_WENT_WRONG_NOTIFICATION,
} from "@constants/commons";
import leftImgBgnSrc from "@assets/img/landing_bg_left.png";
import rightImgBgnSrc from "@assets/img/landing_bg_right.png";
import { getFormattedError } from "@services/http";
import { useNotification } from "@hooks/common/useNotification";
import { Notification } from "@components/Notification";
import axios from "axios";

export const AboutUsContactUs = () => {
  const [isFormSent, setIsFormSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const inputs: IInputFields[] = useMemo(
    () => [
      { name: "name", placeholder: "Name" },
      { name: "companyName", placeholder: "Company" },
      { name: "phoneNumber", placeholder: "Phone Number" },
      { name: "message", placeholder: "Write your message..." },
    ],
    []
  );
  const formMethods = useForm<ILandingContactUsForm>({
    resolver: yupResolver(validationSchemas.landingContactUs),
    mode: "onSubmit",
    defaultValues: {
      name: "",
      companyName: "",
      phoneNumber: "",
      message: "",
    },
    reValidateMode: "onSubmit",
    shouldFocusError: false,
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = formMethods;
  const { ref: textareaRegisterRef, ...textareaRegisterRest } =
    register("message");
  const isErrors = !!Object.keys(errors).length;
  const isNotification = isErrors || isFormSent;

  const {
    isActive,
    message: notificationMessage,
    type: notificationType,
    showNotification,
    closeNotification,
  } = useNotification();

  const resizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "40px";
      const newHeight = Math.min(textarea.scrollHeight, 300);
      textarea.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    resizeTextarea();
  }, []);

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "Home",
      "End",
      "Tab",
    ];
    if (e.ctrlKey || e.metaKey) {
      return;
    }
    if (
      e.currentTarget.value.length >= MAX_CONTACT_US_MESSAGE_LENGTH &&
      !allowedKeys.includes(e.key)
    ) {
      e.preventDefault();
    }
  };

  const onSubmit = async (data: ILandingContactUsForm) => {
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_LINK}/api/v1/landing/contact-us`,
        data
      );
      reset();
      setIsFormSent(true);
    } catch (error) {
      showNotification(SOMETHING_WENT_WRONG_NOTIFICATION, "danger");
      return Promise.reject(getFormattedError(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Notification
        type={notificationType}
        message={notificationMessage}
        isActive={isActive}
        closeNotification={closeNotification}
        isFixed={true}
      />
      <section className="landing-contact-us" id="landing-contact-us">
        <img
          src={leftImgBgnSrc}
          alt=""
          style={{ top: "-60px" }}
          className="landing__bg-img landing__bg-img--left"
        />
        <img
          src={rightImgBgnSrc}
          alt=""
          style={{ top: "-60px" }}
          className="landing__bg-img landing__bg-img--right"
        />
        <div className="landing-contact-us__container landing__section d-flex a-center">
          <h2
            className="landing__section-title landing-contact-us__title"
            style={isNotification ? { marginBottom: "16px" } : {}}
          >
            Contact us
          </h2>
          {isNotification && (
            <div className="landing-contact-us__form-notification d-flex a-center">
              <i
                className={`icon ${
                  isErrors
                    ? "icon-landing-form-warning"
                    : "icon-landing-form-sent"
                }`}
              />
              <span>
                {isErrors ? FORM_HAS_ERRORS_MESSAGE : FORM_SENT_SUCCESS_MESSAGE}
              </span>
            </div>
          )}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="landing-contact-us__form d-flex a-center"
          >
            {inputs.map((input) => (
              <div key={input.name} className="landing-contact-us__input-box">
                {input.name !== "message" ? (
                  <input
                    type="text"
                    {...register(input.name)}
                    placeholder={input.placeholder}
                    className="landing-contact-us__input"
                  />
                ) : (
                  <textarea
                    {...textareaRegisterRest}
                    ref={(e) => {
                      textareaRegisterRef(e);
                      textareaRef.current = e;
                    }}
                    placeholder={input.placeholder}
                    className="landing-contact-us__input landing-contact-us__input--textarea"
                    onChange={resizeTextarea}
                    onKeyDown={handleKeyDown}
                  ></textarea>
                )}
                {errors[input.name] && (
                  <p className="landing-contact-us__input-error">
                    {errors[input.name]?.message}
                  </p>
                )}
              </div>
            ))}
            <button
              disabled={isLoading}
              type="submit"
              className={`landing-contact-us__submit-btn landing__main-btn d-flex a-center jc-center${
                isLoading ? " landing-contact-us__submit-btn--loading" : ""
              }`}
            >
              {isLoading ? <i className="load-loader loader icon" /> : null}
              <span>Send Message</span>
            </button>
          </form>
        </div>
      </section>
    </>
  );
};
