import { useState, useEffect } from "react";
import firstImgSrc from "@assets/img/feature_img_1.png";
import secondImgSrc from "@assets/img/feature_img_2.png";
import thirdImgSrc from "@assets/img/feature_img_3.png";
import forthImgSrc from "@assets/img/feature_img_4.png";

export const AboutUsFeatures = () => {
  const [isFirefox, setIsFirefox] = useState(false);

  useEffect(() => {
    setIsFirefox(navigator.userAgent.includes("Firefox"));
  }, []);

  const features = [
    {
      titlePoint: "Easy to use",
      message: "interface for visibility at multiple levels",
      imgSrc: firstImgSrc,
    },
    {
      titlePoint: "Fully customizable",
      message: "products and measurements",
      imgSrc: secondImgSrc,
    },
    {
      titlePoint: "for small to large groups",
      message: "FitEvent app allows easy measurement capture",
      imgSrc: thirdImgSrc,
    },
    {
      titlePoint: "Offline mode",
      message:
        "allows users to collect size and fit data anywhere even if WiFi or mobile data is limited",
      imgSrc: forthImgSrc,
    },
  ];

  return (
    <div className="landing-features" id="landing-features">
      {features.map((feature, index) => (
        <div
          className="landing__box landing-features__item-box d-flex a-center"
          key={index}
        >
          <div className="landing-features__item-message d-flex a-center">
            <span
              className={`landing-features__item-number${
                isFirefox ? " landing-features__item-number--firefox" : ""
              }`}
              data-text={`0${index + 1}`}
            >{`0${index + 1}`}</span>
            <div className="d-flex landing-features__item-description">
              <span className="landing-features__item-label">Feature</span>
              <h3 className="landing-features__item-title">
                {index === 2 && (
                  <>
                    <span>{feature.message}</span>
                    <br />
                  </>
                )}
                {feature.titlePoint}
                {index === 1 && <br />}
                {index !== 2 && <span> {feature.message}</span>}
              </h3>
            </div>
          </div>
          <div className="landing-features__img-box">
            <img src={feature.imgSrc} alt={feature.titlePoint} />
          </div>
        </div>
      ))}
    </div>
  );
};
