export const AboutUsBenefits = () => {
  const benefits = [
    {
      iconClassName: "icon-drive-point",
      text: "Drive efficiency in each step of the measuring or fitting process",
    },
    {
      iconClassName: "icon-delivering",
      text: "Bring consistency, delivering a pleasant experience for end users",
    },
    {
      iconClassName: "icon-visibility",
      text: "Add visibility to fitting events and measurement data for all stakeholders",
    },
    {
      iconClassName: "icon-time-eliminating",
      text: "Save time by eliminating redundant data entry steps and size conversion",
    },
  ];

  return (
    <div className="landing-benefits" id="landing-benefits">
      <h2 className="landing__section-title landing-benefits__title">
        Benefits
      </h2>
      <ul className="landing__box landing-benefits__box d-flex">
        {benefits.map((benefit, index) => (
          <li className="landing-benefits__item d-flex a-center" key={index}>
            <div className=" landing-benefits__icon-box d-flex a-center jc-center">
              <i className={`icon ${benefit.iconClassName}`} />
            </div>
            <p className="landing-benefits__description">{benefit.text}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};
