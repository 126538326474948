import {
  EndUserEventStatus,
  EventCreator,
  EventStatus,
} from "@models/common/events";
import {
  ALL_STATUS,
  COMPLETED_STATUS,
  IN_PROGRESS_STATUS,
  SCHEDULED_STATUS,
  CANCELED_STATUS,
  MEASUREMENT_REQUIRED_STATUS,
  MEASURED_STATUS,
} from "./events";
import { IGenderForUploadExcels } from "@models/components/secondary/uploadedexcelfilesmodal";

export const SIGN_IN_PASSWORD = "SIGN_IN_WITH_PASSWORD";
export const SIGN_IN_CODE = "SIGN_IN_WITH_CODE";
export const SIGN_IN_PHONE_CODE = "SIGN_IN_WITH_PHONE_CODE";
export const SIGN_IN_EMAIL_CODE = "SIGN_IN_WITH_EMAIL_CODE";
export const SIGN_IN_SUBMIT_CODE = "SIGN_IN_WITH_SUBMIT_CODE";
export const SIGN_IN_NEW_PASSWORD = "SIGN_IN_WITH_NEW_PASSWORD";
export const SIGN_IN_FORGOT_PASSWORD = "SIGN_IN_WITH_FORGOT_PASSWORD";

export const SIGN_IN_AUTH_FLOW = "SIGN_IN";
export const SIGN_UP_AUTH_FLOW = "SIGN_UP";
export const FORGOT_PASSWORD_AUTH_FLOW = "FORGOT_PASSWORD";

export const CREATE_PASS_PAGE = "CREATE_PASS_PAGE";
export const FORGOT_PASS_PAGE = "FORGOT_PASS_PAGE";

export const EMAIL_COGNITO_USERNAME_TYPE = "EMAIL_COGNITO_USERNAME_TYPE";
export const PHONE_NUMBER_COGNITO_USERNAME_TYPE =
  "PHONE_NUMBER_COGNITO_USERNAME_TYPE";

export const EMAIL_PLACEHOLDER = "jacknick@gmail.com";
export const PHONE_NUMBER_START_CODE = "+1";
export const PHONE_NUMBER_PLACEHOLDER = `${PHONE_NUMBER_START_CODE}1234567890`;

export const CODE_CONF_SUBMIT = "CODE_CONF_SUBMIT";
export const CODE_RESEND_SUBMIT = "CODE_RESEND_SUBMIT";

export const MAX_INPUT_LENGTH = 128;
export const LOADER_MAX_TIMEOUT = 10;
export const MINI_LOADER_MAX_TIMEOUT = 5;
export const ERROR_LIMIT_COUNT = 3;
export const SAVE_STORE_INTERVAL = 200;
export const TABLE_ROWS_LIMIT = 7;
export const TABLE_PAGINATION_DIVIDER = 5;
export const SMALL_ITEMS_COUNT_WITHOUT_PAGINATION = 50;
export const DEFAULT_ITEMS_COUNT_WITHOUT_PAGINATION = 100;
export const MIN_PHONE_NUMBER_LENGTH = PHONE_NUMBER_START_CODE.length;

export const OTP_VERIFIER = "OTP_VERIFIER";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const SEARCH_QUERY_KEY = "searchQuery";

export const ADMIN_ROLE = "ADMIN";
export const PARENT_ROLE = "PARENT";
export const DEALER_ROLE = "DEALER";
export const DEALER_SALES_REP_ROLE = "DEALER SALES_REP";
export const SALES_REP_ROLE = "SALES_REP";
export const END_USER_ROLE = "END_USER";

export const PENDING_USER_STATUS = "PENDING";
export const ACCEPTED_USER_STATUS = "ACCEPTED";
export const DELETED_USER_STATUS = "DELETED";

export const MALE_USER_GENDER = "MALE";
export const FEMALE_USER_GENDER = "FEMALE";
export const OTHER_USER_GENDER = "OTHER";

export const arrayOfMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const genders = [
  { id: 1, value: "Male" },
  { id: 2, value: "Female" },
  { id: 3, value: "N/A" },
];

export const simpleGenders: IGenderForUploadExcels[] = [
  { id: 1, name: "Male", value: "MALE", active: true },
  { id: 2, name: "Female", value: "FEMALE", active: false },
];

export const DATES_FILTERS_TITLE = "Dates";
export const LOCATION_FILTERS_TITLE = "Department location";
export const STATUS_FILTERS_TITLE = "Status";
export const CREATOR_FILTERS_TITLE = "Creator";
export const PRODUCT_FILTERS_TITLE = "Product types";

export const filterAllStatusesButtons = [
  { id: 1, value: ALL_STATUS },
  { id: 2, value: COMPLETED_STATUS },
  { id: 3, value: IN_PROGRESS_STATUS },
  { id: 4, value: SCHEDULED_STATUS },
  { id: 5, value: CANCELED_STATUS },
] as { id: number; value: EventStatus }[];

export const filterUserStatusesButtons = [
  { id: 1, value: ALL_STATUS },
  { id: 2, value: MEASUREMENT_REQUIRED_STATUS },
  { id: 3, value: MEASURED_STATUS },
] as { id: number; value: EndUserEventStatus }[];

export const NO_NETWORK_PLUG_TYPE = "NO_NETWORK_PLUG";
export const NO_EVENTS_PLUG_TYPE = "NO_EVENTS_PLUG";
export const NO_SALES_REP_PLUG_TYPE = "NO_SALES_REP_PLUG";
export const NO_DEALERS_PLUG_TYPE = "NO_DEALERS_PLUG";
export const NO_PARENTS_PLUG_TYPE = "NO_DEALERS_PLUG";
export const NO_FOUND_PLUG_TYPE = "NO_FOUND_PLUG";
export const NO_END_USERS_PLUG_TYPE = "NO_END_USERS_PLUG";
export const NO_PRODUCT_TYPES_PLUG_TYPE = "NO_PRODUCT_TYPES_PLUG_TYPE";

export const EVENTS_BREADCRUMBS = "EVENTS_BREADCRUMBS";
export const SALES_REPS_BREADCRUMBS = "SALES_REPS_BREADCRUMBS";
export const DEALERS_BREADCRUMBS = "DEALERS_BREADCRUMBS";
export const PARENTS_BREADCRUMBS = "PARENTS_BREADCRUMBS";
export const MEASUREMENTS_BREADCRUMBS = "MEASUREMENTS_BREADCRUMBS";
export const MY_END_USER_MEASUREMENTS_BREADCRUMBS =
  "MY_END_USER_MEASUREMENTS_BREADCRUMBS";
export const END_USER_MEASUREMENTS_BREADCRUMBS =
  "END_USER_MEASUREMENTS_BREADCRUMBS";
export const END_USERS_BREADCRUMBS = "END_USERS_BREADCRUMBS";

export const filterCreatorButtons = [
  { id: 1, value: ALL_STATUS },
  { id: 2, value: "Admin" },
  { id: 3, value: "Parent" },
  { id: 4, value: "Dealer" },
  { id: 5, value: "Sales representative" },
] as { id: number; value: EventCreator }[];

export const measurementsImageExtensions = [".png", ".jpg", ".jpeg"];
export const bulkFilesExtensions = [".xlsx"];

export const CANCEL_EVENT_NOTIFICATION = "Event was canceled!";
export const DELETE_EVENT_NOTIFICATION = "Event was deleted!";
export const DOWNLOAD_TEMPLATE_NOTIFICATION = "Template was downloaded!";
export const SENT_TEMPLATE_NOTIFICATION = "Template was sent to your email!";
export const DOWNLOAD_REPORT_NOTIFICATION = "Report was downloaded!";
export const DELETE_USER_NOTIFICATION = "The user was deleted!";
export const DELETE_USER_EVENT_NOTIFICATION =
  "The user was deleted from current event!";
export const FILE_DELETED_NOTIFICATION = "File deleted successfully!";
export const FILE_DELETED_ERROR_NOTIFICATION =
  "Failed to delete the file. Please try again.";
export const DELETE_PRODUCT_TYPE_NOTIFICATION = "The Product type was deleted!";
export const UPDATE_MEASUREMENT_NOTIFICATION =
  "The Measurement types list was updated!";
export const UPLOADED_AND_SAVED_NOTIFICATION =
  "File uploaded and saved successfully!";
export const SOMETHING_WENT_WRONG_NOTIFICATION =
  "Something went wrong! Please, try again.";
export const FILE_FAULTED_FORMULAS_NOTIFICATION =
  "An error occurred, please check the file for faulted formulas and try again.";

export const SELF_MEASURED = "Self-measured";
export const ANY_FILES = "No uploads available.";

export const PRODUCT_TYPES_SEARCH_PLACEHOLDER = "Product type";

export const UPLOADED_EXCELS_TITLE = "Uploaded excels";
export const RECOMMENDED_SIZE_TITLE = "Recommended size";

export const COULDNT_CALCULATE_SIZE =
  "Sorry but we couldn't calculate the recommended size. Please check all data and try again or select size manually.";
export const NOT_RECOMMENDED_SIZE_TITLE =
  "You have selected a size that is not recommended";
export const RECOMMENDED_SIZE_MESSAGE =
  "Based on your measurements we recommend size";

export const MAX_CONTACT_US_MESSAGE_LENGTH = 300;
export const INVALID_PHONE_FORMAT = "Invalid format. Example: +12345678910";
export const MAX_CONTACT_US_MESSAGE = (number: number) =>
  `The maximum number of characters must be less than ${number}`;

export const FORM_HAS_ERRORS_MESSAGE =
  "We can’t process your message. Please review the fields highlighted in color below.";
export const FORM_SENT_SUCCESS_MESSAGE = "Your message has been sent.";
