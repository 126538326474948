export const REQUIRED_FIELD = "Please fill in the field";
export const REQUIRED_FIELD_LANDING = "This field is required.";

export const EMAIL_TEMPLATE_MATCH = "Email is not correct. Please check it";

export const PHONE_NUMBER_TEMPLATE_MATCH =
  "Phone number is not correct. Please check it";

export const CODE_TEMPLATE_LENGTH = "Code must be 6 digits";
export const CODE_LOGIN_ERROR = "The provided code is not valid or has expired";

export const PASSWORD_TEMPLATE_MATCH =
  "Minimum 8 characters: uppercase and lowercase characters, numbers, and special characters.";
export const PASSWORD_REQUIRED = "Password is required";

export const RETRY_PASSWORD_MATCH =
  "Your new password doesn't match your confirmation password";
export const RETRY_PASSWORD_REQUIRED = "Retry password is required";
